/* custom css */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600&display=swap');

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
html {
	scroll-behavior: smooth;
}
#wrapper {
	position: relative;
	min-height: 100vh;
}
body {
	font-family: 'Montserrat', sans-serif !important;
	font-size: 14px;
	font-weight: 300;
	background-color: #fff;
}

.font_color {
	color: #f58026;
	font-size: 26px;
}

.main-section {
	text-align: center;
}
.light-bg {
	background-color: #646469;
}
.bg-light {
	background-color: #fff;
}

.pack_content h1 {
	font-size: 48px;
	color: #646469;
	letter-spacing: 0.5px;
	text-transform: capitalize;
	margin-bottom: 0;
	margin-top: 20px;
}
.pack_content span {
	font-size: 16px;
	color: #646469;
	letter-spacing: 0.5px;
}
.navbar {
	transition: all 0.5s ease;
}
.sticky {
	position: fixed;
	left: 0;
	right: 0;
	top: 0px;
	z-index: 999;
	width: 100%;
	background-color: #fff;
}
.sticky2 {
	position: fixed;
	left: 0;
	right: 0;
	top: 74px;
	z-index: 9999;
	width: 100%;
	background-color: #fff;
}
.step-sticky {
	position: fixed;
	left: 0;
	right: 0;
	top: 75px;
	z-index: 999;
	width: 100%;
	background-color: #fff;
}
.btn-active {
	background-color: #f58026;
	color: #fff;
	border: none;
	outline: none;
}
hr {
	border: solid 1px #ddd;
}
.head_line {
	text-align: left;
	color: #000;
	margin-bottom: 30px;
	position: relative;
}
.head_line::before {
	content: '';
	display: block;
	width: 33px;
	height: 3px;
	background: #f58026;
	left: 0;
	bottom: -4px;
	position: absolute;
	z-index: 99;
}

.head_line::after {
	content: '';
	display: block;
	width: 33px;
	height: 3px;
	background: #f58026;
	left: 0;
	bottom: -4px;
	position: absolute;
}
.head_des {
	text-align: left;
	display: block;
	font-size: 18px;
}
.main_box {
	position: relative;
}
.icon_box {
	width: 70px;
	height: 70px;
	background: #ea7e26;
	border-radius: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: -15px;
	right: -15px;
}
.b_border {
	background-color: #ddd;
	border-radius: 6px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
}
.price_list ul li {
	margin: 5px 0 12px;
	text-align: left;
}
.pb-100 {
	padding-bottom: 130px;
}
.pb-200 {
	padding-bottom: 200px;
}
/* .pluse_list ul {
	list-style: none;
} */
.pluse_list ul li {
	margin: 5px 0 12px;
	text-align: left;
}
.sub-head {
	padding: 0 250px;
}
.bottom-part {
	border-bottom: 1px solid #e1e1e1;
}
.back-button {
	border: 1px solid #ddd;
}
.total-font {
	font-size: 18px;
}
.font-style {
	font-size: 14px;
	border: 1px solid #ddd;
	border-radius: inherit;
}
.select-app {
	width: 360px;
	border: 1px solid #ddd;
	border-radius: 0.625rem;
	font-size: 16px;
}
.line-through {
	text-decoration: line-through;
}
.appartment-main {
	text-align: 'center';
}
.gray-background {
	background-color: #f0f0f0;
}
.partbutton {
	border-bottom: solid 2px #fff;
}
.font-modal {
	font-size: 16px;
	color: #000;
}
.parttotal {
	border-bottom: solid 2px #000;
}
.motor_bottom {
	border-bottom: solid 1px #ddd;
}
.cust_table .ant-table-tbody > tr > td,
.cust_table .ant-table-thead > tr > th {
	border-bottom: solid 1px #ddd;
	border-right: solid 1px #ddd;
}
.cust_table .ant-table table {
	border: solid 1px #ddd;
	border-right: none;
	border-bottom: none;
}
.visa-card {
	box-sizing: border-box;
	font-variant: tabular-nums;
	list-style: none;
	font-feature-settings: 'tnum';
	position: relative;
	display: inline-block;
	width: 100%;
	min-width: 0;
	padding: 8.5px 11px;
	color: #455560;
	font-size: 14px;
	line-height: 1.5;
	background-color: #fff;
	background-image: none;
	border-radius: 0.625rem;
	transition: all 0.3s;
	margin: 0 0 24px;
}

.footer-section {
	margin-top: 50px;
	text-align: center;
	background-color: #646469;
	color: #f8f9fa;
	position: absolute;
	z-index: 1;
	padding: 25px 0;
	width: 100%;
	text-align: center;
	bottom: 0;
}
.card-border {
	border: 1px solid #cecece;
	transition: 0.5s;
}
.card-border:hover {
	border: 1px solid #f58026;
}
.card-border:hover h2 {
	color: #f58026;
}

.nav-item {
	color: #fff !important;
	text-decoration: none !important;
	font-size: 15px;
	font-weight: bold;
	letter-spacing: 0.2px;
}
.nav-item:hover {
	text-decoration: none;
}
.nav-item-active {
	color: #f58026 !important;
	text-decoration: none !important;
	font-size: 15px;
	font-weight: bold;
	letter-spacing: 0.2px;
}
.nav-item-active:hover {
	text-decoration: none !important;
}
.pay_border {
	border: solid 2px #646469;
}
.motor-btn {
	/* padding: 1rem 1.5rem !important; */
	line-height: 0.5 !important;
	border-radius: none;
}

.row_evenly {
	justify-content: space-evenly !important;
}
.login-shadow {
	box-shadow: 0px 7px 29px 0 rgba(100, 100, 111, 0.2);
}

/* ant design changes */
.ant-input:hover {
	border-color: #f58026;
}
.ant-result-error .ant-result-icon > .anticon {
	color: red;
}

.ant-input-focused,
.ant-input:focus {
	border-color: #f58026;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	border: none;
	background-color: none;
	border-radius: initial;
}
.ant-spin-dot-item {
	background-color: #f58026;
}
.ant-select {
	font-weight: 500;
}
.ant-menu {
	margin: revert;
}
.ant-menu-horizontal {
	border-bottom-color: #646469;
}
.active-btn .ant-btn.active {
	color: #f58026;
}
.ant-input:placeholder-shown {
	/* font-weight: 600; */
}
option:first {
	color: #999;
}
select:invalid {
	font-weight: 600;
}
a {
	color: #000;
	text-decoration: underline;
}
.ant-btn {
	padding: 3.5px 1.25rem;
}
.ant-modal-content {
	padding: 12px;
}
.ant-pagination-item-active {
	background: #f58026;
}
a:hover {
	color: #000;
	text-decoration: underline;
}
.ant-btn-primary {
	color: rgb(255, 255, 255);
	background: #f58026;
	border-color: #f58026;
}
.ant-select-selection__placeholder {
	color: #f58026;
	font-weight: 600;
}
.ant-checkbox-checked .ant-checkbox-inner {
	background-color: #f58026;
	border-color: #f58026;
}
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
	border-color: #f58026;
}
.ant-form-item-label > label {
	color: #181818;
	font-weight: 600;
}
.ant-btn-primary:focus,
.ant-btn-primary:hover {
	color: rgb(255, 255, 255);
	background: #646469;
	border-color: #646469;
}
.ant-steps-item-process
	> .ant-steps-item-container
	> .ant-steps-item-content
	> .ant-steps-item-title {
	color: #ff8c00;
	font-weight: 600;
}
.ant-steps-item-finish
	> .ant-steps-item-container
	> .ant-steps-item-content
	> .ant-steps-item-title::after,
.ant-steps-item-finish
	> .ant-steps-item-container
	> .ant-steps-item-tail::after {
	background-color: #646469;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
	.ant-select-selector {
	border-color: none;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
	border-bottom: solid 2px #f58026;
}
.ant-steps-item-process
	.ant-steps-item-icon
	> .ant-steps-icon
	.ant-steps-icon-dot,
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
	background: #f58026;
}
.ant-steps-item-finish
	> .ant-steps-item-container
	> .ant-steps-item-content
	> .ant-steps-item-title::after,
.ant-steps-item-finish
	> .ant-steps-item-container
	> .ant-steps-item-tail::after {
	color: 646469;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
	color: #646469;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
	border-color: #646469;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
	color: #646469;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-body
	> table
	> tbody
	> tr
	> .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-body
	> table
	> tbody
	> tr
	> td,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-body
	> table
	> tfoot
	> tr
	> .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-body
	> table
	> tfoot
	> tr
	> td,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-body
	> table
	> tfoot
	> tr
	> th,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-body
	> table
	> thead
	> tr
	> .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-body
	> table
	> thead
	> tr
	> th,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> tbody
	> tr
	> .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> tbody
	> tr
	> td,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> tfoot
	> tr
	> .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> tfoot
	> tr
	> td,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> tfoot
	> tr
	> th,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> thead
	> tr
	> .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> thead
	> tr
	> th,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-header
	> table
	> tbody
	> tr
	> .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-header
	> table
	> tbody
	> tr
	> td,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-header
	> table
	> tfoot
	> tr
	> .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-header
	> table
	> tfoot
	> tr
	> td,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-header
	> table
	> tfoot
	> tr
	> th,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-header
	> table
	> thead
	> tr
	> .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-header
	> table
	> thead
	> tr
	> th {
	border-right: 1px solid #646469;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
	.ant-select-selector {
	/* border-color: #646469; */
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
	color: #f58026;
}
.ant-select-item
	.ant-select-item-option
	.ant-select-item-option-active
	.ant-select-item-option-selected {
	color: #f58026;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
	color: #f58026;
}
.ant-steps-item-finish .ant-steps-item-icon,
.ant-steps-item-process .ant-steps-item-icon {
	border-color: #f58026;
}
.ant-card-meta-description {
	min-height: 60px;
}
.ant-steps-icon {
	color: #f58026;
}

/* media query style */
@media (max-width: 1399px) {
	.sub-head {
		padding: 0 100px;
	}
}

@media (max-width: 1024px) {
	.sub-head {
		padding: 0 50px;
	}
}
@media (max-width: 768px) {
	.sub-head {
		padding: 0 0px;
	}
}
@media (min-width: 1399px) {
	.motor-btn {
		padding: 2rem 1rem !important;
		line-height: 0.5 !important;
		border-radius: none;
	}
}
